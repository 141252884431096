var Hints;

Hints = (function() {
  var self;
  self = this;
  return {
    get_top: function() {
      return $.ajax({
        url: $('.ui.search').data('searchtop'),
        type: "POST",
        dataType: 'json',
        error: function(data) {
          var top;
          return top = [
            {
              title: ''
            }
          ];
        },
        success: function(data) {
          if ((data == null) || (data != null ? data.error : void 0)) {
            self.data_error({
              status: 500,
              statusText: 'Server error'
            });
            return;
          }
          return $('.ui.search').search({
            source: data,
            onSelect: function(r, rs) {
              return Hints.onResultClick(r.title);
            },
            cache: false,
            searchFullText: false
          });
        }
      });
    },
    init: function() {
      var query;
      self = this;
      query = null;
      return $('.prompt').on('keypress', function(e) {
        var adult_filter, risky_filter;
        adult_filter = $('#toggle').prop('checked');
        risky_filter = $('#toggle_risky').prop('checked');
        query = $('.prompt').val();
        return $('.ui.search').search({
          onSelect: function(r, rs) {
            return Hints.onResultClick(r.title);
          },
          apiSettings: {
            url: ($('.ui.search').data('search').trim()) + "?query={query}&adult=" + adult_filter + "&risky=" + risky_filter
          },
          searchDelay: 500,
          cache: false
        });
      });
    },
    onResultClick: function(query) {
      var categoty_id, id, table;
      id = $('.ui.search').search('get result').id;
      if (query.length < 2) {
        query = null;
      }
      window.scrollTo(0, 0);
      history.pushState({}, null, 'search?q=' + query);
      document.title = query + ' - BitLord Power Search';
      table = TorrentsTable;
      categoty_id = table.filters.time = 4;
      table.search(query);
      $("#all_time_filter").removeClass("hidden");
      Filters.search_breadcrumbs(query);
      Filters.filter($("#all_time_filter"), false);
      return $('.ui.search').search('destroy');
    }
  };
})();

$(document).ready(function() {
  return Hints.init();
});
